import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import ClosedExceptions from '../components/ClosedExceptions';
import RestaurantTimeManagement from './RestaurantTimeManagement';
import RestaurantEstimatedTimes from './RestaurantEstimatedTimes';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Separator from '../components/Separator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {fetchRestaurantDetails, updateRestaurant} from '../actions/restaurantActions';
import ScrollInputOptions from '../components/ScrollInputOptions';
import {ContainerBox, 
  Header, 
  Body, 
  OrangeButton, 
  Title, 
  Container, 
  CardHeader, 
  CardHeaderTitle,
  FormGroup,
  InsideFormGroup} from '../components/DefaultImports'
  import { toast, ToastContainer } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

  const Form = styled.form`
  max-width: 600px;
  margin: 0 auto;
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #007bff;
  border: 1px solid #007bff;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;

  &:hover {
    background-color: #0069d9;
    border-color: #0062cc;
  }
`;



const generateIntervalOptions = () => {
  const intervalOptions = [];
  for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 60; j += 15) {
          // Format the hour and minute values to have two digits
          const hour = i.toString().padStart(2, '0');
          const minute = j.toString().padStart(2, '0');
          const time = `${hour}:${minute}`;

          if (time >= '00:15' && time <= '08:00') {
              intervalOptions.push(time);
          }
      }
  }
  return intervalOptions;
};

function Time() {


    const dispatch = useDispatch();
    const params = useParams();
    const [restaurant_id, setRestaurant_id] = useState(params.id);

    const [eachTime, setEachTime] = useState('00:15');

    const [errorMessage, setErrorMessage] = useState('ok_status');
    const [showError, setShowError] = useState(false);
    const [intervalOptions, setIntervalOptions] = useState(generateIntervalOptions());

      useEffect(() => {
          dispatch(fetchRestaurantDetails(restaurant_id));
      },[dispatch, restaurant_id]);

        function checkEmptyStates() {
          let emptyStates = [];

          if (!eachTime) {
            emptyStates.push('Cada tempo');
          } else {
            console.log('debugging here ok')
            return 'ok_status';
          }
        }
      

      function checkForErrorsAndCreateRestaurant() {
          console.log('hereee in checkForErrorsAndCreateRestaurant')
          let status_error = checkEmptyStates();
          setErrorMessage(status_error);
          if (status_error === 'ok_status') {
            console.log('status_error: ', status_error)
            setShowError(false);

            const updatedRestaurantParams = {
              each_time: eachTime,
            };
              dispatch(updateRestaurant(restaurant_id, updatedRestaurantParams));
          } else {
            setShowError(true);
            console.log('it s false: ', status_error)
          }
        }

        const handleSubmit = (e) => {
          e.preventDefault();
          checkForErrorsAndCreateRestaurant();
          toast.success('Guardado com successo', {
              style: { color: '#EE754C' },
              icon: <FontAwesomeIcon icon={faCheckCircle} 
              style={{ color: '#EE754C'}}
            />,
            progressStyle: { backgroundColor: '#EE754C' },
            autoClose: 1000
          });
        };


        const handleIntervalTimeChange = (option) => {
          setEachTime(option);
        };

  return (
    <Container>
      <ToastContainer />
        <CardHeader>
          <CardHeaderTitle>
          Horários
          </CardHeaderTitle>
        </CardHeader>
        <Row>
            <Col xs={12} md={12} xl={12}>
                <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <InsideFormGroup>
                    <div style={{marginRight:'5px'}}>
                    (Horários disponíveis) permitir criação de reservas a cada
                    </div>
                    
                    <ScrollInputOptions
                      handleOptionsChange={handleIntervalTimeChange}
                      options={intervalOptions}
                      currentOption={eachTime}
                    />
                    <div style={{marginLeft:'5px'}}>
                    minutos.
                    </div>
                    
                  </InsideFormGroup>
                </FormGroup>
                {errorMessage !== 'ok_status' && 
                  <div style={{color:'red', marginBottom:'10px'}}> {errorMessage} </div>
                }
                <Button type="submit">Guardar</Button>
                </Form>
                
                <RestaurantTimeManagement restaurant_id={restaurant_id}/>
                <ClosedExceptions restaurant_id={restaurant_id}/>
                <EstimatedTimePage restaurant_ids={restaurant_id}/>
            </Col>
        </Row>
        
    </Container>
  )
}

export default Time