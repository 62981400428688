import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

const DropDownButtonLeft = styled.div`
  padding: 0;
  display: flex;
  height: 100%;
  width: 70%;
  align-items: center;
  justify-content: center;
  border-right: 1px solid gray;
  cursor: pointer;
  color: ${(props) => (props.isPeopleAndZero ? 'black' : 'gray')};
  font-size: 17px;
  background-color: ${(props) => (props.isPeopleAndZero ? '#EE754C' : 'white')};
  border-radius: 5px 0 0 5px;`
;

const DropDownButtonRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: gray;
  text-align: center;
  width: 30%;
  cursor: pointer;
  background-color: white;
  border-radius: 0 5px 5px 0;`
;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid gray;
  border-radius: 4px;
  margin-right:4px;

  width: 60px; 
  min-width: 60px; /* Minimum size */
  max-width: max-content; 
`;

const DropDownButton = ({ options, unit, setUnit }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const selectedRef = useRef(null);

  useEffect(() => {
    if (isOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({ block: 'center' });
    }
  }, [isOpen]);

  const handleSelect = (option, e) => {
    e.preventDefault();
    setUnit(option);
    setIsOpen(false);
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <div className="form-control-people" onClick={(e) => handleOpen(e)}>
      <DropDownButtonLeft>
        {unit}
      </DropDownButtonLeft>
      <DropDownButtonRight>
      <div className={`arrow-icon ${isOpen ? 'rotate-menu-arrow' : ''}`}>
          <FontAwesomeIcon icon={faChevronCircleDown} size="xl" color="#EE754C" />
        </div>
      </DropDownButtonRight>

      {isOpen && (
        <div className="custom-dropdown-menu">
          {options.map((option, index) => (
            <div
            className={`dropdown-menu-item ${option === unit ? 'selected' : ''}`}
              key={index}
              onClick={(e) => handleSelect(option, e)}
              ref={option === unit ? selectedRef : null}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const InputDayOrHour = ({ initialValue='00:00', setValue }) => {
  const [timeUnit, setTimeUnit] = useState('Horas');
  const [number, setNumber] = useState(0);

  useEffect(() => {
    console.log('initialValue: ', initialValue)
  },[initialValue])

  useEffect(() => {
    let [hours, minutes] = initialValue.split(":" ).map(Number);
    if (minutes > 0) {
      hours += 1; // Round up to next hour
    }
    if (hours % 24 === 0) {
      setNumber(hours / 24);
      setTimeUnit('Dias');
    } else {
      setNumber(hours);
      setTimeUnit('Horas');
    }
  }, [initialValue]);

  useEffect(() => {
    const calculatedValue = timeUnit === 'Dias' ? number * 24 : number;
    const formattedHours = Math.floor(calculatedValue).toString().padStart(2, '0');
    setValue(`${formattedHours}:00`);
  }, [number, timeUnit, setValue]);

  return (
    <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
      <Input
        type="number"
        id="number_of_babychairs"
        name="number_of_babychairs"
        value={number}
        onChange={(e) => setNumber(Number(e.target.value))}
      />
      <DropDownButton options={['Dias', 'Horas']} unit={timeUnit} setUnit={setTimeUnit} />
    </div>
  );
};

export default InputDayOrHour;
