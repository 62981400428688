import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';


const DropDownButtonLeft = styled.div`
  padding: 0;
  display:flex;
  height:100%;
  width:70%;
  align-items:center;
  justify-content:center;
  border-right: 1px solid gray; 
  cursor:pointer;
  color: ${props => (props.isPeopleAndZero ? 'black' : 'gray')};
  font-size:17px;
  background-color: ${props => (props.isPeopleAndZero ? '#EE754C' : 'white')};
  border-radius: 5px 0 0 5px;
`;
const DropDownButtonRight = styled.div`
  display: flex;  /* Add this */
  align-items: center;  /* Add this */
  justify-content:center;
  font-size: 12px;
  color: gray;
  text-align: center;
  width: 30%;
  cursor: pointer;
  background-color: white;
  border-radius: 0 5px 5px 0;
`;

function DropDownButton({ options, handleOptionChange, initialOption, isPeople=false, update, setUpdate=false}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(initialOption);
  useEffect(() => {
    setSelectedOption(initialOption);
  }, [initialOption])
  const selectedRef = useRef(null);

  useEffect(() => {
    if (isOpen && selectedRef.current) {
      selectedRef.current.scrollIntoView({  block: "center" });
    }
  }, [isOpen]);

  const handleSelect = (option, e) => {
    e.preventDefault();
    setSelectedOption(option);
    handleOptionChange(option);
    
    setIsOpen(false);
    if (setUpdate) {
      setUpdate(update + 1);
    }
  };

  const handleOpen = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
    console.log('isopen: ', isOpen)
    console.log('options: ', options)
  }

  return (
      <div className="form-control-people" onClick={(e) => handleOpen(e)}>
        <DropDownButtonLeft isPeopleAndZero={isPeople && selectedOption === 0}>
            {isPeople && selectedOption === 0 ? 'nº Pessoas': selectedOption} 
        </DropDownButtonLeft>
        <DropDownButtonRight>
            <div className={`arrow-icon ${isOpen ? "rotate-menu-arrow" : ""}`}>
            <FontAwesomeIcon 
                icon={faChevronCircleDown} 
                size='xl'
                color="#EE754C"
            />
            </div>
        </DropDownButtonRight>
        
        {isOpen && (
        <div className="custom-dropdown-menu">
          {options.map((option, index) => (
            <div 
              className={`dropdown-menu-item ${option === selectedOption ? 'selected' : ''}`}
              key={index} 
              onClick={(e) => handleSelect(option, e)}
              ref={option === selectedOption ? selectedRef : null}
            >
                {option}
            </div>
          ))}
        </div>
      )}
      </div>
  );
}

export default DropDownButton;

