import styled from 'styled-components';

export const ContainerBox = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top:10px;
  background-color: #F0EBE0;
  border-radius: 5px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #99B0AD;
  color: white;
  border-radius: 5px 5px 0 0;
`;

export const Body = styled.div`
  height:300px;
  overflow-y: auto;
  margin-top: 20px;
  padding: 10px;
  /* Style the scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
    padding-bottom:10px !important;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Light gray track */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Darker gray thumb */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Slightly darker on hover */
  }
`;

export const OrangeButton = styled.button`
  padding: 8px 11px;
  background-color: #EE754C;
  color: white;
  font-size: 16px;
  font-weight:600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #EE561B;
  }
`;

export const Title = styled.h4`
  margin: 0;
  font-weight: 500;
  color: white;
`;

export const Container = styled.div`
  max-width:1200px;
  height:100%;
  margin: 0 auto;
  padding:30px;
  padding-top:60px;
    @media (max-width: 768px) {
    padding:5px;
    padding-top:0;
    margin:0;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  height:60px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #FAF6E9;
  color: white;
  border-radius: 5px;
  margin-bottom:20px;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
`;

export const CardHeaderTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  color: #EE754C;
`;

export const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

export const InsideFormGroup = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;