import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { DateRangePicker } from 'react-date-range';
import { pt } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import CustomSelectArray from '../components/CustomSelectArray';
import  SwitchOnAndOff  from '../components/SwitchOnAndOff'
import axios from 'axios';
import Loader from './Loader';
import { api } from '../App';
import {
  fetchClosedExceptions,
  createClosedExceptions,
  fetchPlaceOfTable,
} from '../actions/restaurantActions';
import styled from 'styled-components';
import DropDownButton from '../components/DropDownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCalendar, faMapLocation, faCircleXmark, faClock, faUser, faSun } from '@fortawesome/free-solid-svg-icons';
import {  
  XSvgIcon,
} from '../icons/SvgIcons';
import {ContainerBox, Header, Body, OrangeButton, Title, Container} from '../components/DefaultImports'

const StyledDateRangePicker = styled(DateRangePicker)`
  width: auto;
  margin: 0 auto;
  border-radius: 10px;
  .rdrDefinedRangesWrapper {
    display: none;
  }
`;

const HorizontalItemBox = styled.div`
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
`;

const ActionButton = styled.button`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #EE754C;
  font-size: 20px;
  cursor: pointer;

  &:hover {
    color: #4B3335;
  }
`;

const SubmitButton = styled(OrangeButton)`
  background-color: #9FBBC1;
  border: none;

  &:hover {
    background-color: #4B3335;
  }
`;


const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const InsideFormGroup = styled.div`
  width:100%;
  display: flex;
  flex:wrap;
`;

const Input = styled.input`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;

  width: 60px; /* Initial size */
  min-width: 60px; /* Minimum size */
  max-width: max-content; /* Automatically expands to fit the content */
`;



const ClosedExceptions = ({ restaurant_id }) => {
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    if (restaurant_id) {
      dispatch(fetchClosedExceptions(restaurant_id));
      dispatch(fetchPlaceOfTable(restaurant_id));
    }
  }, [dispatch, restaurant_id]);

  const restaurantZones = useSelector((state) => state.restaurantZones);
  const { zones = [] } = restaurantZones;

  const closedExceptions = useSelector((state) => state.closedExceptions);
  const { exceptions } = closedExceptions;

  const [showModal, setShowModal] = useState(false);
  const [zone_list, setZone_list] = useState([]);
  const [selectedZones, setSelectedZones] = useState([]);
  const [numberOfAcceptance, setNumberOfAcceptance] = useState(0);
  const [showExceptionDaysDeleteModal, setShowExceptionDaysDeleteModal] = useState(false);
  const [exception_id, setException_id] = useState(false);
  const [switchLimit, setSwitchLimit] = useState(false);
  const [numbersList, setNumbersList] = useState(() => {
    const list = [];
    for (let i = 0; i <= 100; i++) {
      list.push(i);
    }
    return list;
  });

  useEffect(() => {
    const placeOfTables = zones.map((zone) => zone.place_of_table);
    setZone_list(placeOfTables);
    setSelectedZones(placeOfTables);
    const joinedString = placeOfTables.join(',');
    setFormData({ ...formData, ['list_of_zones']: String(joinedString) });
  }, [zones]);

  const [formData, setFormData] = useState({
    restaurant: parseInt(restaurant_id),
    closed_days_start: formatDate(new Date()),
    closed_days_end: formatDate(new Date()),
    start_time: '',
    end_time: '',
    list_of_zones: '',
    number_of_acceptance: '0',
  });

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleNumberOfAcceptance = (value) => {
    setFormData({ ...formData, ['number_of_acceptance']: String(value) });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createClosedExceptions(restaurant_id, formData));
    setShowModal(false);
  };

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const handleDateRangeChange = (ranges) => {
    setDateRange([
      {
        ...ranges.selection,
        startDate: ranges.selection.startDate,
        endDate: ranges.selection.endDate,
      },
    ]);

    setFormData({
      ...formData,
      closed_days_start: ranges.selection.startDate
        ? formatDate(ranges.selection.startDate)
        : '',
      closed_days_end: ranges.selection.endDate
        ? formatDate(ranges.selection.endDate)
        : '',
    });
  };

  const handleSelectZones = (newSelectedItems) => {
    const joinedString = newSelectedItems.join(',');
    setFormData({ ...formData, ['list_of_zones']: joinedString });
    setSelectedZones(newSelectedItems);
  };

  const handleExceptionElimination = async (value) => {
    try {
      const response = await api.delete(
        `/restaurants/${restaurant_id}/closedexceptions/${value}/`
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setShowExceptionDaysDeleteModal(false);
    dispatch(fetchClosedExceptions(restaurant_id));
  };

  const handleModalClose = () => {
    setShowExceptionDaysDeleteModal(false);
  };
  const handleModalOpen = (value) => {
    setShowExceptionDaysDeleteModal(true);
    setException_id(value);
  };

  return (
    <ContainerBox>
      <Header>
        <Title>Turnos excecionais</Title>
        <OrangeButton onClick={handleShow}>
        Novo turno excecional <FontAwesomeIcon icon={faPlus} />
        </OrangeButton>
      </Header>
      {Array.isArray(exceptions) ? (
        <Body>
          {exceptions.map((exception) => (
            <HorizontalItemBox key={exception.id}>
              <div>Turno excecional</div>
              <div>Horário reduzido/Horário extendido/Restaurante fechado/Sala fechada</div>
              <Row>
                <Col xs={6} md={3}>{exception.closed_days_start} {exception.closed_days_start !== exception.closed_days_end && <> / {exception.closed_days_end}</>}</Col>
                <Col sm={0} md={4}>{exception.list_of_zones.replace(/,/g, ', ')}</Col>
                <Col sm={0} md={2}>{exception.number_of_acceptance}</Col>
                <Col sm={0} md={2}>{exception.start_time} - {exception.end_time}</Col>
              </Row>
              <ActionButton onClick={() => handleModalOpen(exception.id)}>
                  <XSvgIcon                
                    width={20}
                    height={20}
                    strokeColor={"#99B0AD"}
                    style={{marginLeft:'4px', marginRight:'4px'}}
                    />
              </ActionButton>
            </HorizontalItemBox>
          ))}
        </Body>
      ) : (
        <Loader />
      )}

      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Novo turno excecional</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <FormGroup controlId="dateRange">
              <Form.Label>Data de início e de fim</Form.Label>
              <StyledDateRangePicker
                ranges={dateRange}
                onChange={handleDateRangeChange}
                minDate={new Date()}
                showDateDisplay={false}
                months={1}
                direction="horizontal"
                locale={pt}
              />
            </FormGroup>

            <FormGroup controlId="startTime">
              <Form.Label>Hora de início</Form.Label>
              <Form.Control
                type="time"
                name="start_time"
                value={formData.start_time}
                onChange={handleChange}
                required
              />
            </FormGroup>
            <FormGroup controlId="endTime">
              <Form.Label>Hora de fim</Form.Label>
              <Form.Control
                type="time"
                name="end_time"
                value={formData.end_time}
                onChange={handleChange}
                required
              />
            </FormGroup>
{/*             <FormGroup>
              <span>Cada horário disponível aceita até </span>
                  <Input
                    type="number"
                    id="accept_people_per_each_time"
                    name="accept_people_per_each_time"
                    value={selectedZones}
                    onChange={handleSelectZones}
                  />
                  <span> pessoas, acumuladas entre todas as reservas. 
                    <div style={{fontSize:'12px'}}>
                    Para ver e alterar os horários disponíveis consultar secção Horários.
                    </div></span>
                
              </FormGroup>  */}
            <FormGroup controlId="zones">
              <Form.Label>Salas</Form.Label>
              <CustomSelectArray
                items={zone_list}
                onSelectChange={handleSelectZones}
                selectedItems={selectedZones}
              />
            </FormGroup>

            <div className="text-right">
              <OrangeButton variant='danger' onClick={handleClose}>
               Cancelar
              </OrangeButton>
              <SubmitButton type="submit">
                Gravar
              </SubmitButton>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showExceptionDaysDeleteModal} onHide={handleModalClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar exceção</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem a certeza de que deseja eliminar esta exceção?</Modal.Body>
        <Modal.Footer>
          <OrangeButton onClick={handleModalClose} variant="secondary">
            Cancelar
          </OrangeButton>
          <OrangeButton onClick={() => handleExceptionElimination(exception_id)} variant="danger">
            Eliminar
          </OrangeButton>
        </Modal.Footer>
      </Modal>
    </ContainerBox>
  );
};

function formatDate(date) {
  const d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  return [year, month.padStart(2, '0'), day.padStart(2, '0')].join('-');
}

export default ClosedExceptions;
