import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import Loader from '../components/Loader';
import { Modal } from 'react-bootstrap';
import SelectablePopUpOptions from '../components/SelectablePopUpOptions';
import CustomSelect from '../components/SelectableCustom';
import CustomSelectArray from '../components/CustomSelectArray';
import ClosedExceptions from '../components/ClosedExceptions';
import EstimatedTimePage from '../components/EstimatedTimeCustomersSpend';
import Separator from '../components/Separator';
import { Row, Col } from 'react-bootstrap';
import {
  fetchRestaurantOpenDays,
  patchRestaurantOpenDays,
  createRestaurantOpenDays,
  fetchEstimatedTimeCustomersSpend,
  patchEstimatedTimeCustomersSpend,
  createEstimatedTimeCustomersSpend,
  fetchClosedExceptions,
  patchClosedExceptions,
  createClosedExceptions,
  fetchHoursEach,
} from '../actions/restaurantActions';
import { api } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark, faHourglassStart, faHourglassEnd, faFilePen } from '@fortawesome/free-solid-svg-icons';
import DropDownButton from '../components/DropDownButton';
import {  
  XSvgIcon,
} from '../icons/SvgIcons';
import {ContainerBox, Header, Body, OrangeButton, Title} from '../components/DefaultImports'


const OpenDayCard = styled.div`
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  background-color: white;
  position:relative;
`;

const WeekdaysContainer = styled.div`
  display: flex;
  flex-wrap:wrap;
  gap: 8px;
  margin-top: 10px;
  margin-right: 30px;
`;

const Weekday = styled.div`
  border: 1px solid #4b3335;
  border-radius: 12px;
  padding: 4px 8px;
  background-color: ${(props) => (props.isOpen ? '#ee754c' : 'transparent')};
  color: ${(props) => (props.isOpen ? 'white' : '#4b3335')};
`;


const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: #75726f;
  border: 1px solid #75726f;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
  &:hover {
    background-color: #474645;
    border-color: #474645;
  }
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;
  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;

const ActionButtons = styled.div`
  position:absolute;
  left:90%;
  bottom:17%;
  display:flex;
  flex-wrap:wrap;
  gap: 10px;
  justify-content:in-between;
  margin-top: 10px;
`;


const ActionButton = styled.button`
  background: none;
  border: none;
  color: #75726f;
  font-size: 25px;
  cursor: pointer;
  width:100%;

  &:hover {
    color: #4B3335;
  }
`;

function RestaurantTimeManagement({ restaurant_ids = false, handleNextSteps = false, currentStep = false }) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [timeStart, setTimeStart] = useState('12:00');
  const [timeEnd, setTimeEnd] = useState('22:00');
  const [shift, setShift] = useState('Dinner');
  const [selectedItems, setSelectedItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [openShift_id, setOpenShift_id] = useState('');
  const [update_delete, setUpdate_delete] = useState('');
  const [selectedOpenDay, setSelectedOpenDay] = useState(false);
  const [openDaysData, setOpenDaysData] = useState({});

  const dispatch = useDispatch();
  const params = useParams();
  const [restaurant_id, setRestaurant_id] = useState(restaurant_ids ? restaurant_ids : params.id);

  const openDayss = useSelector((state) => state.restaurantOpenDaysOfTheWeek);
  const { openDays, isLoadingOpenDays } = openDayss;
  const hoursEachTime = useSelector((state) => state.hoursEachTime);
  const { hoursEach } = hoursEachTime;

  useEffect(() => {
    dispatch(fetchRestaurantOpenDays(restaurant_id));
    dispatch(fetchHoursEach(restaurant_id));
  }, [dispatch, restaurant_id]);

  useEffect(() => {
    const processWeekdays = () => {
      const weekdaysMap = {
        Segunda: 'Monday',
        Terça: 'Tuesday',
        Quarta: 'Wednesday',
        Quinta: 'Thursday',
        Sexta: 'Friday',
        Sábado: 'Saturday',
        Domingo: 'Sunday',
      };
      const days = Object.keys(weekdaysMap).reduce((acc, day) => {
        acc[weekdaysMap[day]] = selectedItems.includes(day);
        return acc;
      }, {});
      setOpenDaysData(days);
    };
    processWeekdays();
  }, [selectedItems]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const openDaysDataToSubmit = {
      restaurant: restaurant_id,
      shift: shift,
      start_time: timeStart,
      end_time: timeEnd,
      ...openDaysData,
    };
    if (update_delete === 'update') {
      dispatch(patchRestaurantOpenDays(restaurant_id, openShift_id, openDaysDataToSubmit));
    } else {
      dispatch(createRestaurantOpenDays(restaurant_id, openDaysDataToSubmit));
    }
    setModalIsOpen(false);
  };

  const handleOpenTimeModal = (type, shift_id = '') => {
    setUpdate_delete(type);
    if (type === 'update') {
      setOpenShift_id(shift_id);
      const openDay = openDays.find((day) => day.id === shift_id);
      if (openDay) {
        setShift(openDay.shift);
        setTimeStart(openDay.start_time.slice(0, 5));
        setTimeEnd(openDay.end_time.slice(0, 5));
        const selected = [];
        if (openDay.Monday) selected.push('Segunda');
        if (openDay.Tuesday) selected.push('Terça');
        if (openDay.Wednesday) selected.push('Quarta');
        if (openDay.Thursday) selected.push('Quinta');
        if (openDay.Friday) selected.push('Sexta');
        if (openDay.Saturday) selected.push('Sábado');
        if (openDay.Sunday) selected.push('Domingo');
        setSelectedItems(selected);
      }
    }
    setModalIsOpen(true);
  };

  const handleShowModal = (shift_id) => {
    setShowModal(true);
    setOpenShift_id(shift_id);
  };

  const deleteOpenDay = async (openDayId) => {
    try {
      await api.delete(`restaurants/1/opentimes/${openDayId}/`);
    } catch (error) {
      console.error(error);
    }
    dispatch(fetchRestaurantOpenDays(restaurant_id));
    setShowModal(false);
    setModalIsOpen(false);
  };

  return (
    <ContainerBox>
      <Header>
        <Title>Turnos regulares</Title>
        <OrangeButton onClick={() => handleOpenTimeModal('create')}>Criar turno <FontAwesomeIcon icon={faPlus} /></OrangeButton>
      </Header>
      <Body>
      {isLoadingOpenDays ? (
        <Loader />
      ) : (
        openDays && openDays.length > 0 ? (
          openDays.map((openDay) => (
            <OpenDayCard key={openDay.id}>
              <div>Turno regular</div>
              <h5>{openDay.shift}</h5>
              <div>
                <FontAwesomeIcon icon={faHourglassStart} style={{ color: '#ee754c', marginRight: '5px', fontSize:'20px' }} />
                Início {openDay.start_time.slice(0, 5)}

                <FontAwesomeIcon icon={faHourglassEnd} style={{ color: '#ee754c', marginLeft: '10px', marginRight: '5px', fontSize:'20px' }} />
                Fim {openDay.end_time.slice(0, 5)}
              </div>

              <WeekdaysContainer>
                <Weekday isOpen={openDay.Monday}>Segunda</Weekday>
                <Weekday isOpen={openDay.Tuesday}>Terça</Weekday>
                <Weekday isOpen={openDay.Wednesday}>Quarta</Weekday>
                <Weekday isOpen={openDay.Thursday}>Quinta</Weekday>
                <Weekday isOpen={openDay.Friday}>Sexta</Weekday>
                <Weekday isOpen={openDay.Saturday}>Sábado</Weekday>
                <Weekday isOpen={openDay.Sunday}>Domingo</Weekday>
              </WeekdaysContainer>

              <ActionButtons>
                <ActionButton style={{top:'50px'}} onClick={() => handleShowModal(openDay.id)}>
                </ActionButton>
                <ActionButton onClick={() => handleOpenTimeModal('update', openDay.id)}>
                  <FontAwesomeIcon  icon={faFilePen} />
                  
                </ActionButton>
                <ActionButton onClick={() => handleShowModal(openShift_id)}>
                      <XSvgIcon                
                        width={20}
                        height={20}
                        strokeColor={"#99B0AD"}
                        style={{marginLeft:'4px', marginRight:'4px'}}
                        />
                  </ActionButton>
              </ActionButtons>
            </OpenDayCard>
          ))
        ) : (
          <div>Nada para ver aqui</div>
        )
      )}

      </Body>
      

      <Modal show={modalIsOpen} onHide={() => setModalIsOpen(false)}>
        <Modal.Header>
          {update_delete === 'update' ? <h5>Editar turno</h5> : <h5>Criar turno</h5>}
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleFormSubmit}>
            <Row>
              <Col xs={6}>
                <label htmlFor="start_time">Hora de início do turno</label>
                <DropDownButton options={hoursEach} handleOptionChange={setTimeStart} initialOption={timeStart} />
              </Col>
              <Col xs={6}>
                <label htmlFor="end_time">Hora de fim do turno</label>
                <DropDownButton options={hoursEach} handleOptionChange={setTimeEnd} initialOption={timeEnd} />
              </Col>
            </Row>
            <div>
              <label htmlFor="weekdays">Dias da semana</label>
              <CustomSelectArray
                items={['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo']}
                onSelectChange={setSelectedItems}
                selectedItems={selectedItems}
              />
            </div>
            <Modal.Footer>
              <Button type="submit">Gravar</Button>
              <Button type="button" onClick={() => setModalIsOpen(false)}>Cancelar</Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Eliminar turno</Modal.Title>
        </Modal.Header>
        <Modal.Body>Tem a certeza que deseja eliminar este turno?</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal(false)}>Cancelar</Button>
          <DangerButton onClick={() => deleteOpenDay(openShift_id)}>Eliminar</DangerButton>
          
        </Modal.Footer>
      </Modal>
    </ContainerBox>
  );
}

export default RestaurantTimeManagement;
