import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import SubMenu from '../components/SubMenu';
import SelectablePopUpOptions from '../components/SelectablePopUpOptions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faChair, faGear, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import {
  fetchPlaceOfTable,
  fetchTables,
  patchPlaceOfTable,
  patchTables,
  createPlaceOfTable,
  createTables,
  fetchConnectTablesConfig,
} from '../actions/restaurantActions';
import Loader from '../components/Loader';
import ConnectedTablesBox from '../components/ConnectedTablesBox'; 
import { api } from '../App'
import Separator from '../components/Separator';
import DropDownButton from '../components/DropDownButton';
import { Row, Col  } from 'react-bootstrap';
import {ContainerBox, Header, Body, OrangeButton, Title, Container, CardHeader, CardHeaderTitle} from '../components/DefaultImports'

// Styled components
const Wrapper = styled.div`
  position: relative;
  margin: 10px 0;
  box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
  border-radius: 5px;
  color: black;
  border: 1px solid #e8e6e1;
`;

const WrapperTop = styled.div`
  width:100%;
  color:white;
  height:60px;
  background-color:#8FEBC5;
  border-radius: 5px 5px 0 0; 
  margin-bottom:10px;
`;

const WrapperBot = styled.div`
  width:100%;
  height:auto;
  padding:10px;
`;



const ModernButton = styled.button`
  padding: 5px 8px;
  background-color: #007bff;
  color: white;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #0056b3;
  }
`;

const CreateZoneButton = styled(ModernButton)`
  position: absolute;
  top: 11px;
  right: 11px;
  background-color: #59d9d4;
  &:hover {
    background-color: #228a86;
  }
`;

const B2 = styled.div`
  background-color: #f8f9fa;
  position:relative;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`;

const B2Top = styled.div`
  background-color: #58c49f;
  height:30px;
  color:white;
  width:100%;
`;

const B2Bot = styled.div`
  padding: 10px;
  max-height: 250px;
  width: 100%;
  overflow: auto;
  
  /* this is the default color of the scrollbar */
  scrollbar-color: #888 #f2f2f2;
  
  /* this is the width of the scrollbar */
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    /* this is the width of the scrollbar */
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    /* this is the color of the track, where the scrollbar moves */
    background: #f2f2f2;
  }

  &::-webkit-scrollbar-thumb {
    /* this is the color of the scrollbar itself */
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    /* this is the color of the scrollbar when hovered */
    background: #555;
  }
`;

const ZoneTitle = styled.h2`
  color:white;
  font-size:18px;
  padding: 5px;
`;

const TableBox = styled.div`
  display: inline-block;
  margin: 5px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background-color: ${props => (props.isSelected ? '#3ECF95' : 'white')};
  color: ${props => (props.isSelected ? 'white' : 'black')};
  border: ${props => (props.isSelected ? '2px solid gray' : '1px solid gray')};
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box; // Add this line to include the border in the dimensions
`;


const AddTableButton = styled(ModernButton)`
  bottom: 20px;
  right: 10px;
  margin-left: 65%;
`;

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #65DDB1;
  border: 1px solid #65DDB1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  margin-top:10px;
  margin-right:3px;
  &:hover {
    background-color: #47A281;
    border-color: #47A281;
  }
`;

const ButtonAddTables = styled.button`
  display: inline-block;
  font-weight: 400;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-color: #59d9d4;
  border: 1px solid #65DDB1;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 5px;
  margin-top:10px;
  margin-right:3px;
  &:hover {
    background-color: #228a86;
    border-color: #47A281;
  }
`;

const DangerButton = styled(Button)`
  background-color: #ff704d;
  border-color: #ff704d;

  &:hover {
    background-color: #ff471a;
    border-color: #ff471a;
  }
`;

const RemoveZoneButton = styled(DangerButton)`
  position: absolute;
  top: -4px;
  right: 1px;
  width: 20px;
  height: 20px;
  padding: 3px;
  font-size: 18px;
  text-align: center;
  line-height: 5px;
  
`;

// Modal styled component
const Modal = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 90%;
    max-width: 400px;
  }
`;
// Add these styled components
const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Label = styled.span`
  font-weight: bold;
`;

const TablesContainer = styled.div`
  max-height: 300px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
`;

// Add these styled components
const HorizontalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const TableNumber = styled.div`
  background-color: #3ECF95;
  padding: 5px;
  border-radius: 5px;
  color: white;
`;

const CanConnectTables = styled.div`
  display: flex;
  gap: 5px;
`;

const CanConnectTable = styled.div`
  background-color: #7CF4D8;
  padding: 5px;
  border-radius: 5px;
  color: white;
`;

const Seats = styled.div`
  position: relative;
  padding-left: 20px;
`;

const VerticalLine = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #ccc;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  bottom: 0;
  left: 0;
  padding: 10px;
`;

const ButtonsContainerStart = styled.div`
  display: flex;
  justify-content: start;
  align-items: end;
  bottom: 0;
  left: 0;
  padding: 10px;
`;

const LargeIconPlus = styled(FontAwesomeIcon)`
  margin-left: 7px; /* adjust this value as needed */
`;

const StyledInput = styled.input`
    text-align:center;
    padding: 10px;
    margin: 5px;
    border-radius: 20px;
    border: 1px solid #ccc;
    outline: none;
    transition: border-color 0.3s;
    width: 150px;
    
    &:focus {
        border-color: #007BFF;  // You can change this color to your preference.
    }
`;

                    // Table component
function Table({ table, selectedTableId, setSelectedTableId, setCan_connect_tables, setChairNumber, setSelectedTable, setTable_name }) {
  const isSelected = selectedTableId === table.id;

  const handleTableClick = () => {
    setSelectedTableId(table.id);
    setSelectedTable(table.table_number)
    setCan_connect_tables(table.can_connect_tables);
    setChairNumber(table.number_of_seats);
    setTable_name(table.table_name);
  };

  return (
    <TableBox
      isSelected={isSelected}
      onClick={() => handleTableClick()}
    >
      {table.table_name}
    </TableBox>
  );
}
function EditableTable({ table, isSelected, selectedTableId, onClick }) {
  const isPreselected = selectedTableId === table.id;
  const tableStyles = {
    border: isPreselected ? '2px solid grey' :  '1px solid #ccc',
    cursor: isPreselected ? 'not-allowed' : 'pointer',
    backgroundColor: isSelected ? '#A8F8D2' : isPreselected ? '#3ECF95' : 'white',
  };

  return (
    <TableBox style={tableStyles} onClick={isPreselected ? undefined : () => onClick(table.table_number)}>
      {table.table_name}
    </TableBox>
  );
}


                  // Zone component
function ZoneBox({ zone, restaurant_id, step }) {
  const dispatch = useDispatch();
  const params = useParams();

  const restaurantTables = useSelector(state => state.restaurantTables);
  const { tables } = restaurantTables;

  const createConfirm = useSelector(state => state.createConfirmation);
  const { createLoad, isLoadingCreate, errorCreate } = createConfirm;

  const filteredTables = Array.isArray(tables)
  ? tables.filter(table => table.place_of_table === zone.id)
  : false;

  

  // state for managing the modal's open/closed status
  const [isRemoveZoneModalOpen, setIsRemoveZoneModalOpen] = useState(false);
  const [isAddTablesModalOpen, setIsAddTablesModalOpen] = useState(false);
  const [numTablesToAdd, setNumTablesToAdd] = useState(0);
  const [numChairsPerTable, setNumChairsPerTable] = useState(0);
  const [isViewAllConfigModalOpen, setIsViewAllConfigModalOpen] = useState(false);
  const [isRemoveTablesModalOpen, setIsRemoveTablesModalOpen] = useState(false);
  
  // State for Edit Tables modal
  const [removeTablesQuantity, setRemoveTablesQuantity] = useState(false);
  const [isEditTablesModalOpen, setIsEditTablesModalOpen] = useState(false);
  const [selectedTable, setSelectedTable] = useState('1');
  const [selectedTableId, setSelectedTableId] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [chairNumber, setChairNumber] = useState([]);
  const [table_name, setTable_name] = useState('');
  const [can_connect_tables, setCan_connect_tables] = useState('');
  const [isNextTableTrue, setIsNextTableTrue] = useState(false);
  const [selectFirstTable, setSelectFirstTable] = useState(true);
  const [numbersList, setNumbersList] = useState(() => {
    const list = [];
    for (let i = 1; i <= 100; i++) {
      list.push(i);
    }
    return list;
  });

  

  // Modal open and close handlers
  const handleOpenRemoveZoneModal = () => {
    setIsRemoveZoneModalOpen(true);
  };

  const handleCloseRemoveZoneModal = () => {
    setIsRemoveZoneModalOpen(false);
  };

  const handleOpenAddTablesModal = () => {
    setIsAddTablesModalOpen(true);
  };

  const handleCloseAddTablesModal = () => {
    setIsAddTablesModalOpen(false);
  };

  const handleNumTablesToAddChange = (event) => {
    setNumTablesToAdd(parseInt(event.target.value));
  };

  const handleNumChairsPerTable = (event) => {
    setNumChairsPerTable(parseInt(event.target.value));
  };

  const handleNumTablesToRemove = (event) => {
    setRemoveTablesQuantity(parseInt(event.target.value));
  };

  const handleOpenRemoveTablesModal = () => {
    setIsRemoveTablesModalOpen(true);
  };
  const handleCloseRemoveTablesModal = () => {
    setIsRemoveTablesModalOpen(false);
  };

    // Modal open and close handlers
    const handleOpenEditTablesModal = () => {
      const connectedTables = can_connect_tables.split(',') || [];
      setSelectedTables(connectedTables);
      setIsEditTablesModalOpen(true);
    };
    const handleCloseEditTablesModal = () => {
      setIsEditTablesModalOpen(false);
    };
    const handleNumberOfChairChange = (chairs) => {
      setChairNumber(chairs);
    };

    const handleTableNameChange = (e) => {
      setTable_name(e.target.value);
    };

    const handleOpenViewAllConfigModal = () => {
      setIsViewAllConfigModalOpen(true);
    };
    
    const handleCloseViewAllConfigModal = () => {
      setIsViewAllConfigModalOpen(false);
    };

    const handleTableChoosenConfig = (table_number, can_connect_tables, number_of_seats, table_id, the_table_name) => {
      setTable_name(the_table_name);
      setSelectedTableId(table_id);
      setSelectedTable(table_number);
      const connectedTables = can_connect_tables.split(',') || [];
      setSelectedTables(connectedTables);
      setChairNumber(number_of_seats);
      setIsViewAllConfigModalOpen(false);
    };
    const saveEditTable = () => {
      setCan_connect_tables(selectedTables.join(','));
      
      const data = {
        id : selectedTableId,
        table_number : selectedTable,
        number_of_seats : chairNumber,
        can_connect_tables: selectedTables.length > 0 ? selectedTables.filter(str => str !== '').join(',') : '',
        table_name: table_name,
      }
      console.log('data:',data)
      dispatch(patchTables(restaurant_id, selectedTableId, data))
    };
    const saveEditTableAndNext = () => {
      setCan_connect_tables(selectedTables.join(','));
      const data = {
        id : selectedTableId,
        table_number : selectedTable,
        number_of_seats : chairNumber,
        can_connect_tables: selectedTables.length > 0 ? selectedTables.filter(str => str !== '').join(',') : '',
        table_name: table_name,
      }
      dispatch(patchTables(restaurant_id, selectedTableId, data))
      setIsNextTableTrue(true);
    };



    useEffect(() => {
      if (isNextTableTrue){
        console.log('here')
        console.log('table:',tables)
        function findNextTableById() {
          // Filter tables by the zone ID (place_of_table)
          const filteredTables = tables.filter((table) => table.place_of_table === zone.id);
          // Sort filtered tables by table_number (convert strings to numbers)
          const sortedTables = filteredTables.slice().sort((a, b) => Number(a.table_number) - Number(b.table_number));
          // Find the index of the current table
          const currentTableIndex = sortedTables.findIndex((table) => table.id === selectedTableId);
          // If the current table is not found, return null
          if (currentTableIndex === -1) {
            return null;
          }
          // Find the next table with a table_number greater than the current table's table_number
          const currentTableNumber = Number(sortedTables[currentTableIndex].table_number);
          const nextTable = sortedTables.find((table) => Number(table.table_number) > currentTableNumber) || null;
          return nextTable;
        }
        const nextTable = findNextTableById();
        if (nextTable) {
          setSelectedTableId(nextTable.id);
          setSelectedTable(nextTable.table_number);
          setCan_connect_tables(nextTable.can_connect_tables)
          setSelectedTables(nextTable.can_connect_tables.split(','))
          setNumChairsPerTable(nextTable.number_of_seats)
          setChairNumber(nextTable.number_of_seats)
          setTable_name(nextTable.table_name)
        } else {
          console.log('No next table found');
        }
        setIsNextTableTrue(false)
      }
    }, [tables]);

    const handleAddTables = (zone) => {
      const data = {
        place_of_table: zone,
        num_tables_to_create: numTablesToAdd,
        num_seats: numChairsPerTable,
      }
      dispatch(createTables(restaurant_id, data))
      
    };

    const handleRemoveTables = async (zone) => {
      const data = {
        place_of_table: zone,
        num_tables_to_delete: removeTablesQuantity,
      }
      try {
        const response = await api.delete(
          `restaurants/${restaurant_id}/zones/${zone}/delete-tables/${removeTablesQuantity}/`
        );
        // Handle the response here (e.g., update your component's state)
        console.log(response.data);
      } catch (error) {
        // Handle the error here (e.g., display an error message)
        console.error(error);
      }
      dispatch(fetchTables(restaurant_id));
    };
    
  
    const toggleTableSelection = (tableNumber) => {
      if (selectedTables.includes(tableNumber)) {
        setSelectedTables(selectedTables.filter(num => num !== tableNumber));
      } else {
        setSelectedTables([...selectedTables, tableNumber]);
      }
      console.log(selectedTables)
    };

    const deleteZone = async (zone_id) => {
      try {
        const response = await api.delete(
          `/restaurants/1/placeoftable/${zone_id}/`
        );
    
        // Handle the response here (e.g., update your component's state)
        console.log(response.data);
      } catch (error) {
        // Handle the error here (e.g., display an error message)
        console.error(error);
      }

      dispatch(fetchPlaceOfTable(restaurant_id));
      setIsAddTablesModalOpen(false);
    };

    useEffect(() => {
        if (filteredTables && Array.isArray(filteredTables) && filteredTables.length > 0) {
          if (selectFirstTable) {
            setSelectedTableId(filteredTables[0].id);
            setSelectedTable(filteredTables[0].table_number);
            setCan_connect_tables(filteredTables[0].can_connect_tables);
            setChairNumber(filteredTables[0].number_of_seats);
            setTable_name(filteredTables[0].table_name);
            console.log('inside tables');
            setSelectFirstTable(false);
          }
        }
    }, [filteredTables]);

  return (
    <B2>
      <B2Top>
        <ZoneTitle>{zone.place_of_table}</ZoneTitle>
      </B2Top>
      <B2Bot>
        {filteredTables && 
        <>
        {filteredTables.map(table => (
            <Table
              key={table.id}
              table={table}
              selectedTableId={selectedTableId}
              setSelectedTableId={setSelectedTableId}
              setSelectedTable={setSelectedTable}
              setChairNumber={setChairNumber}
              setTable_name={setTable_name}
              setCan_connect_tables={setCan_connect_tables}
            />
          ))}
        </>
          
        }
      </B2Bot> 
      <Row>
        <Col xs={5}>
          <ButtonsContainerStart>
            <ButtonAddTables onClick={handleOpenAddTablesModal}><FontAwesomeIcon size="xl" icon={faChair} /><LargeIconPlus size="xl" icon={faPlus} /></ButtonAddTables>
          </ButtonsContainerStart>
        </Col>
        <Col xs={7}>
          <ButtonsContainer>
              <Button onClick={handleOpenEditTablesModal}><FontAwesomeIcon size="xl" icon={faGear} /></Button>
              <DangerButton onClick={handleOpenRemoveTablesModal} style={{marginLeft: '20px',}}><FontAwesomeIcon size="xl" icon={faCircleXmark} style={{"--fa-primary-color": "#d35612", "--fa-secondary-color": "#ffffff", }} />  <FontAwesomeIcon size="xl" icon={faChair} /></DangerButton>
          </ButtonsContainer>
        </Col>
      </Row>
      
      
      
      
      <RemoveZoneButton onClick={handleOpenRemoveZoneModal}>x</RemoveZoneButton>

      {isRemoveZoneModalOpen && (
        <Modal>
          <div className="modal-content">
            <h3>Eliminar sala</h3>
            <p>Tem a certeza de que deseja eliminar esta sala?</p>
            <br />
            <DangerButton onClick={handleCloseRemoveZoneModal}>Cancelar</DangerButton>
            <Button onClick={() => {
              handleCloseRemoveZoneModal();
              deleteZone(zone.id);
            }}>Eliminar</Button>
          </div>
        </Modal>
      )}
      {isRemoveTablesModalOpen && (
        <Modal>
          <div className="modal-content">
            <h5>Eliminar mesa</h5>
            <label htmlFor="numTablesToAdd">Tem a certeza que deseja eliminar a mesa?</label>
            <input type="number" id="numTablesToRemove" onChange={handleNumTablesToRemove}/>
            <br />
            <DangerButton onClick={handleCloseRemoveTablesModal}>Cancelar</DangerButton>
            <Button onClick={() => {
              // Add table addition functionality here
              handleRemoveTables(zone.id);
              handleCloseRemoveTablesModal();
            }}>Eliminar</Button>
          </div>
        </Modal>
      )}
      {isAddTablesModalOpen && (
        <Modal>
          <div className="modal-content">
            <h3>Adicionar mesas</h3>
            <label htmlFor="numTablesToAdd">Número de mesas a adicionar</label>
            <input type="number" id="numTablesToAdd" onChange={handleNumTablesToAddChange}/>
            <label htmlFor="numTablesToAdd">Número de lugares em cada mesa</label>
            <input type="number" id="numTablesToAdd" onChange={handleNumChairsPerTable}/>
            <br />
            <label htmlFor="numTablesToAdd">Posteriormente, poderá alterar o número de lugares nas definições de cada mesa.</label>
            <DangerButton onClick={handleCloseAddTablesModal}>Cancelar</DangerButton>
            <Button onClick={() => {
              // Add table addition functionality here
              handleAddTables(zone.id);
              handleCloseAddTablesModal();
            }}>Gravar</Button>
          </div>
        </Modal>
      )}
      {isEditTablesModalOpen && (
        <Modal>
          <div className="modal-content">
            <h3>Editar mesa</h3>
            <p>Nome da mesa: 
                  <StyledInput 
                    value={table_name} 
                    onChange={handleTableNameChange}
                    placeholder="Preencher nome"
                  />
                </p>
            <label htmlFor="start_time">
              Grupos de mesas 
            </label>
            <div>
              Permitir junção da mesa com as seguintes mesas próximas
            </div>

            <div className="tables-container">
              <B2Bot> 
              {filteredTables.map(table => {
              const isSelected = selectedTables.includes(table.table_number);
              return (
                <EditableTable
                  key={table.id}
                  table={table}
                  isSelected={isSelected}
                  selectedTableId={selectedTableId}
                  onClick={toggleTableSelection}
                />
              );
            })}
              </B2Bot>
            
            </div>
            <br></br>
            {isLoadingCreate? <Loader></Loader>:
            <>
                

                <label htmlFor="start_time">Número de lugares da mesa</label>
                <DropDownButton
                  options={numbersList}
                  handleOptionChange={handleNumberOfChairChange}
                  initialOption={chairNumber}
                />
                <br />
                <DangerButton onClick={handleCloseEditTablesModal}>Cancelar</DangerButton>
                <Button onClick={handleOpenViewAllConfigModal}>Visualizar todos os grupos de mesas</Button>
                <Button onClick={() => {
                  // Save functionality here
                  saveEditTable();
                }}>Gravar</Button>
                
                  <Button onClick={() => {
                    // Save and next functionality here
                    saveEditTableAndNext();
                  }}>Guardar e configurar próxima mesa</Button>
            </>
            }
          </div>
        </Modal>
      )}
      {isViewAllConfigModalOpen && (
      <Modal>
        <div className="modal-content">
          <h3>Grupos de mesas</h3>
          <p>Selecionar uma linha para ir para a edição da mesa correspondente</p>
          <LabelsContainer>
            <Label>Mesa</Label>
            <Label>Mesas agrupáveis</Label>
            <Label>Número de lugares da mesa</Label>
          </LabelsContainer>
          <TablesContainer>
      {filteredTables.map(table => (
        <HorizontalBox
          key={table.id}
          onClick={() => {
            handleTableChoosenConfig(table.table_number, table.can_connect_tables, table.number_of_seats, table.id, table.table_name)
          }}
        >
          <TableNumber>{table.table_name}</TableNumber>
          <CanConnectTables>
            {table.can_connect_tables.split(',').map(canConnectTable => (
              <CanConnectTable key={canConnectTable}>
                {canConnectTable}
              </CanConnectTable>
            ))}
          </CanConnectTables>
          <Seats>
            <VerticalLine />
            {table.number_of_seats}
          </Seats>
        </HorizontalBox>
      ))}
    </TablesContainer>
          <br />
          <Button onClick={handleCloseViewAllConfigModal}>
            OK
          </Button>
        </div>
      </Modal>
    )}
    </B2>
  );
}
            // Main function here
function RestaurantZonesTablesManagement({restaurant_id=false, handleNextSteps=false, currentStep=false}) {
  const dispatch = useDispatch();
  const params = useParams();

  const [restaurantId, setRestaurantId] = useState(restaurant_id ? restaurant_id : params.id);
  const [zoneName, setZoneName] = useState(false);

  const restaurantZones = useSelector(state => state.restaurantZones);
  const { zones = []} = restaurantZones;
  
  const connectTables = useSelector(state => state.connectTablesConfig);
  const { connectTablesConfig = [] } = connectTables;

  useEffect(() => {
    dispatch(fetchPlaceOfTable(restaurantId));
    dispatch(fetchTables(restaurantId));
    dispatch(fetchConnectTablesConfig(restaurantId));
  }, [dispatch, restaurantId]);
    
  useEffect(() => {
      if (restaurant_id) {
        setRestaurantId(restaurant_id);
      }
  },[restaurant_id])

    // Add state for managing the modal's open/closed status
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Modal open and close handlers
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = (save) => {
      setIsModalOpen(false);
      if (save == 'save') {
        const data = {
          restaurant:restaurantId, 
          place_of_table: zoneName,
  
        };
        dispatch(createPlaceOfTable(restaurantId, data));
      }
    };



return (
  <Container>
    <Row>
      <Col sm={12} md={12} xl={12}>
          <CardHeader>
            <CardHeaderTitle>
              Salas e mesas
            </CardHeaderTitle>
          </CardHeader>
        {currentStep === 'tables_and_zones' ||  currentStep === false ?
          <ContainerBox>
            <Header>
              <Title>Salas</Title>
              <OrangeButton onClick={handleOpenModal}>
                <h7>Adicionar sala</h7><LargeIconPlus size="xl" icon={faPlus} />
              </OrangeButton>
            </Header>
            <Body>
            {Array.isArray(zones) ? (
              <div>
                {zones.map(zone => (
                  <ZoneBox 
                  key={zone.id} 
                  zone={zone} 
                  restaurant_id={restaurantId} 
                  step={currentStep}
                  />
                ))}
              </div>
            ) : (
              <Loader></Loader>
            )}
            </Body>
           
          </ContainerBox>
        :
          <></>
        }
        
        {isModalOpen && (
          <Modal>
            <div className="modal-content">
              <h3>Adicionar sala</h3>
              <input
                type="text"
                placeholder="Nome da sala"
                onChange={(event) => setZoneName(event.target.value)}
              />
              <br />
              <br />
              <DangerButton onClick={handleCloseModal}>Cancelar</DangerButton>
              <Button onClick={() => {
                // Add save functionality here
                handleCloseModal('save');
              }}>Gravar</Button>
            </div>
          </Modal>
        )}
       {/*  {currentStep === 'connect_tables_rules' || currentStep === false ?
          <ContainerBox>
            <Header>
            <Title>Conexão de mesas</Title>
            </Header>

            <WrapperBot>
                {Array.isArray(connectTablesConfig) ? (
                <ConnectedTablesBox 
                connectedTablesArray={connectTablesConfig}
                restaurant_id={restaurantId} 
                />
              ) : (
                <Loader></Loader>
              )}
            </WrapperBot>
          
          </ContainerBox>
        :
          <></>
        } */}
      </Col>
    </Row>
    
    
  </Container>
);
}

export default RestaurantZonesTablesManagement