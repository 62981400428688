// Importing React and styled-components
import React from 'react';
import styled from 'styled-components';

// Styled components for the switch
const SwitchWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 45px; // Set the width as you prefer
  height: 22px; // Set the height as you prefer
`;

const SwitchLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: gray;
  border-radius: 15px;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 2px; // Adjust the position based on the height of the switch
    left: ${props => (props.isOn ? '18px' : '3px')}; // Adjust the position for ON/OFF state
    width: 18px; // Adjust the knob size as you prefer
    height: 18px; // Adjust the knob size as you prefer
    background-color: white;
    border-radius: 50%;
    transition: 0.2s;
  }
  background-color: ${props => (props.isOn ? '#40d69d' : '#c2c0c0')};
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
  &:checked + ${SwitchLabel} {
    background-color: #EE754C;
    &::after {
      content: "";
      position: absolute;
      left: 22px;
      transition: 0.2s;
    }
  }
`;

// SwitchButton Component
const SwitchOnAndOff = ({ isOn, handleToggle, id=false }) => {
  return (
    <SwitchWrapper >
      <SwitchInput
        id={id ? + id : 'switch-checkbox'}
        type="checkbox"
        checked={isOn}
        onClick={handleToggle}
      />
      <SwitchLabel htmlFor={id ? + id : 'switch-checkbox'} isOn={isOn} />
    </SwitchWrapper>
  );
};

export default SwitchOnAndOff;
