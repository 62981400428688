import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pt } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {
    fetchReservationBlockedDays,
    fetchReservationBlockedWeekdays,
  } from '../actions/reservationsActions';
import Messages from './Messages'
import Loader from './Loader'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';

const DatePickerWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-datepicker {
    width: 100%;
    height: 100%;
    border: none;
    box-shadow: none;
  }

  .react-datepicker__header {
    background-color: white;
    border-bottom: none;
  }

  .react-datepicker__day {
    width: 40px;
    line-height: 40px;
    font-size: 15px;
    margin: 7px 7px !important;
    border-radius: 5px;
    border: 1px solid black !important;
  }

  .react-datepicker__day:hover {
    background-color: inherit !important;
    opacity: 1 !important;
    transform: none !important;
    box-shadow: none !important;
  }

  .react-datepicker__day--selected {
    background-color: #25d9b2 !important;
    border-radius: 5px;
    border: 1px solid black;
  }

  .react-datepicker__day--selected:hover {
    background-color: #61ab9b !important;
  }

  .react-datepicker__day--disabled {
    text-decoration: line-through;
    background-color: #f5f5f5 !important;
    border-radius: 5px;
    border:none !important;
  }

  .react-datepicker__current-month {
    font-size: 1.2rem;
  }

  .react-datepicker__day-name {
    font-weight: bold;
    padding: 0 0 10px 0 !important;
    margin: 0 12px !important;
  }

  @media (max-width: 458px) {
    font-size: 1.9rem;
    .react-datepicker__day {
      width: 1.9rem;
      height: 1.9rem;
      line-height: 1.9rem;
    }
    .react-datepicker__month-container {
      width: auto;
    }
    .react-datepicker__day-name {
      gap: 5px;
      margin: 0 9px !important;
    }
  }

  @media (max-width: 350px) {
    font-size: 1rem;
    .react-datepicker__day {
      width: 1.5rem;
      height: 1.5rem;
      line-height: 1.5rem;
    }
    .react-datepicker__month-container {
      width: auto;
    }
    .react-datepicker__day-name {
      gap: 5px;
      margin: 0 5px !important;
    }
  }
`;

const CustomDatePicker = ({ selectedDate, onDateChange, id, handleCloseDateModal = false }) => {
  const dispatch = useDispatch();
  const [currentTimeZone, setCurrentTimeZone] = useState('Europe/London');
  const reservationBlockedDays = useSelector((state) => state.reservationBlockedDays);
  const { blockedDays } = reservationBlockedDays;
  const reservationBlockedWeekDays = useSelector((state) => state.reservationBlockedWeekDays);
  const { blockedWeekdays, isLoading, error, isRestaurantOpen, restaurantTimeZone } = reservationBlockedWeekDays;


  useEffect(() => {
    console.log('--- ENVIRONMENT DEBUG ---');
    console.log('Current Time (UTC):', new Date().toISOString());
    console.log('Current Time Zone (JS):', Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log('Server Time Zone (from API):', restaurantTimeZone ? restaurantTimeZone.timezone : 'Not set');
  }, [restaurantTimeZone]);

  useEffect(() => {
    console.log('--- ENVIRONMENT blockedDays ---');
    console.log('blockedDays',blockedDays && blockedDays);
    if (isRestaurantOpen) {
      console.log('isRestaurantOpen: ', isRestaurantOpen)
    }
  }, [blockedDays, isRestaurantOpen]);


  useEffect(() => {
    console.log('Calling fetchReservationBlockedDays and fetchReservationBlockedWeekdays');
    dispatch(fetchReservationBlockedDays(id));
    dispatch(fetchReservationBlockedWeekdays(id));
    console.log('Called fetchReservationBlockedDays and fetchReservationBlockedWeekdays');
  }, [dispatch]);

  let blockedWeekdayss = [];
  if (blockedWeekdays !== undefined && blockedWeekdays !== '') {
    blockedWeekdayss = blockedWeekdays
      .split(',')
      .map((str) => parseInt(str.trim()))
      .filter((num) => !isNaN(num));
  }

  useEffect(() => {
    if (restaurantTimeZone) {
      setCurrentTimeZone(restaurantTimeZone.timezone);
    }
  }, [restaurantTimeZone]);

  let blockedDayss;
  let dateObject = null;

  if (isRestaurantOpen && isRestaurantOpen.block_day !== 'No day needs to be blocked.') {
    let dateParts = isRestaurantOpen.block_day.split("-");
    dateObject = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
  }

  if (blockedDays) {
    blockedDayss = blockedDays.split(',').map(dateStr => {
      const [day, month, year] = dateStr.split('-').map(Number);
      let blockedDate = new Date(year, month - 1, day);
      return utcToZonedTime(blockedDate, currentTimeZone);
    });
  } else {
    blockedDayss = [];
  }

  if (dateObject !== null) {
    blockedDayss.push(dateObject);
  }

  const isBlocked = (date) => {
    const day = date.getDay();
  
    console.log('Blocked Days Array:', blockedDayss);
  
    const isBlockedDay = Array.isArray(blockedDayss) && blockedDayss.length > 0 && blockedDayss.some((blockedDate) => {
      console.log('This should not log if blockedDayss is empty');
      return (
        blockedDate.getDate() === date.getDate() &&
        blockedDate.getMonth() === date.getMonth() &&
        blockedDate.getFullYear() === date.getFullYear()
      );
    });
  
    const isBlockedWeekday = blockedWeekdayss.includes(day);
  
    console.log(`Date: ${date}, isBlockedDay: ${isBlockedDay}, isBlockedWeekday: ${isBlockedWeekday}`);
    return isBlockedDay || isBlockedWeekday;
  };
/*   useEffect(() => {
    if (!selectedDate) {
      return;
    }

    let currentDate = utcToZonedTime(new Date(selectedDate.getTime()), currentTimeZone);

    if (!isBlocked(currentDate)) {
      onDateChange(currentDate);
      return;
    }

    currentDate.setDate(currentDate.getDate() + 1);

    while (isBlocked(currentDate)) {
      currentDate.setDate(currentDate.getDate() + 1);
    }

    let dateInUTC = zonedTimeToUtc(currentDate, currentTimeZone);

    onDateChange(dateInUTC);
  }, [blockedDays, blockedWeekdays, currentTimeZone]); */

  let minDate = utcToZonedTime(new Date(), currentTimeZone);

  const getTodayInServerTimeZone = () => {
    const now = new Date();
    const zonedNow = utcToZonedTime(now, currentTimeZone);
    zonedNow.setHours(0, 0, 0, 0);  // Set to midnight in server time zone
    return zonedNow;
  };

  useEffect(()=> {
    minDate = getTodayInServerTimeZone();
    console.log('minDate', minDate)
  },[currentTimeZone])

  return (
    <DatePickerWrapper>
      {isLoading ? (
        <h2><Loader /></h2>
      ) : blockedWeekdays ? (
        <DatePicker
          inline
          locale={pt}
          selected={selectedDate}
          dayClassName={(date) => {
            let classNames = "";

            if (isBlocked(date)) {
              classNames += " react-datepicker__day--disabled";
            } else {
              classNames += " react-datepicker__day--selectable";
            }

            return classNames.trim();
          }}
          onChange={(date) => {
            console.log("Selected date (before conversion):", date);
            date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
            let dateInUTC = zonedTimeToUtc(date, currentTimeZone);
            console.log("Selected date (after conversion):", dateInUTC);
            onDateChange(dateInUTC);
            if (handleCloseDateModal !== false) {
              handleCloseDateModal();
            }

            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
          }}
          filterDate={(date) => !isBlocked(date)}
          minDate={minDate}
        />
      ) : error ? (
        <h2><Messages variant='danger'>{error}</Messages></h2>
      ) : (
        <h2>Nothing to be found</h2>
      )}
    </DatePickerWrapper>
  );
};

export default CustomDatePicker;
